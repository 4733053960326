<template>
  <!-- 地图 -->
  <div class="Map">
    <Head :name = 'headerName' :showBack='true'></Head>
    <div class="wrap">
      <div class="detail">
        <div class="title">
          <div class="p1">族谱成员</div>
          <div class="p2">实时统计族内成员数量</div>
          <div class="p3" @click="toFujin">附近成员<i></i></div>
        </div>
        <div class="number">
          <div>
            <span class="p1">在世人数</span>
            <span class="p2">{{info.zaishi}}</span>
          </div>
          <div>
            <span class="p1">已逝人数</span>
            <span class="p2 p4">{{info.qushi}}</span>
          </div>
          <div>
            <span class="p1">在世男</span>
            <span class="p2">{{info.zaishi_nan}}</span>
          </div>
          <div>
            <span class="p1">在世女</span>
            <span class="p2">{{info.zaishi_nv}}</span>
          </div>
          <div>
            <span class="p1">已逝男</span>
            <span class="p2 p4">{{info.qushi_nan}}</span>
          </div>
          <div>
            <span class="p1">已逝女</span>
            <span class="p2 p4">{{info.qushi_nv}}</span>
          </div>
        </div>
      </div>
      <div id="echart" style="height: 480px;width:100%"></div>
    </div>
  </div>
</template>

<script>
import 'echarts/map/js/china'
export default {
  data() {
    return {
      headerName: '族谱地图',
      msg: '', // 提示内容
      showAlert: false, // 提示显示
      ifload: false, // loading
      info: {}
    }
  },
  mounted () {
    this.$get('/index/map',{},(res)=>{
      if (res.data.code == 1) {
        this.info = res.data.data;
      }
    })
  },
  methods: {
    toFujin () {
      this.$router.push({name: 'Fujin'})
    }
  },
  watch: {
    info:function() {
      this.$nextTick(function(){
        // 初始化echarts实例
        this.chinachart = this.$echarts.init(document.getElementById('echart'))
        // 进行相关配置
        this.chartOption = { 
          tooltip: { // 鼠标移到图里面的浮动提示框
          // formatter详细配置： https://echarts.baidu.com/option.html#tooltip.formatter
            formatter (params, ticket, callback) { 
              // params.data 就是series配置项中的data数据遍历
              let localValue
              if (params.data) {
                localValue = params.data.value
              } else { // 为了防止没有定义数据的时候报错写的
                localValue = 0
              }
              let htmlStr = `
                <div style='font-size:18px;'> ${params.name}</div>
                <p style='text-align:left;margin-top:5px;'>
                  人数：${localValue}<br/>
                </p>
              `
              return htmlStr
            }
            // backgroundColor:"#ff7f50",//提示标签背景颜色
            // textStyle:{color:"#fff"} //提示标签字体颜色
          }, 
          // visualMap的详细配置解析：https://echarts.baidu.com/option.html#visualMap
          visualMap: { // 左下角的颜色区域
            type: 'piecewise', // 定义为分段型 visualMap
            min: 0,
            // max: 1000,
            itemWidth: 20,
            bottom: 0,
            left: 20,
            pieces: [ // 自定义『分段式视觉映射组件（visualMapPiecewise）』的每一段的范围，以及每一段的文字，以及每一段的特别的样式
              {gt: 1000, lte: 10000, label: '1000+', color: '#6ad86e'}, // (1000, 10000]
              {gt: 500, lte: 1000, label: '500-1000', color: '#9adcfa'}, // (500, 1000]
              {gt: 200, lte: 500, label: '200-500', color: '#ffeb3b'}, // (200, 500]
              {gt: 100, lte: 200, label: '100-200', color: 'orange'}, // (100, 200]
              {gt: 0, lte: 100, label: '0-100', color: '#ff9800'}, // (0, 100]
              {value: 0, label: '无数据', color: '#eee'} // [0]
            ]
          },
          // geo配置详解： https://echarts.baidu.com/option.html#geo
          geo: { // 地理坐标系组件用于地图的绘制
            map: 'china', // 表示中国地图
            // roam: true, // 是否开启鼠标缩放和平移漫游
            zoom: 1.2, // 当前视角的缩放比例（地图的放大比例）
            label: {
              show: true
            },
            itemStyle: { // 地图区域的多边形 图形样式。
              borderColor: 'rgba(0, 0, 0, 0.2)',
              emphasis: { // 高亮状态下的多边形和标签样式
                shadowBlur: 20,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          },
          series: [
            {
              name: '', // 浮动框的标题（上面的formatter自定义了提示框数据，所以这里可不写）
              type: 'map',
              geoIndex: 0,
              label: {
                show: true
              },
              // 这是需要配置地图上的某个地区的数据，根据后台的返回的数据进行拼接（下面是我定义的假数据）
              // data: [{
              //   name: '北京',
              //   value: 100,
              // }]
              data: this.info.map
            }
          ]
        }
        // 使用刚指定的配置项和数据显示地图数据
        this.chinachart.setOption(this.chartOption)
      })
    }
  },
}
</script>

<style lang='scss' scoped>
.Map {
  .wrap {
    position: absolute;
    top: 0;
    width: 100%;
    .detail {
      background: white;
      padding-bottom: 10px;
      .title {
        position: relative;
        padding: 10px;
        .p1 {
          font-size: 16px;
        }
        .p2 {
          font-size: 14px;
          color: #999;
        }
        .p3{
          position: absolute;
          right: 10px;
          top: 10px;
          font-size: 15px;
          i {
            display: inline-block;
            width: 10px;
            height: 10px;
            border-right: 1px solid #999;
            border-bottom: 1px solid #999;
            transform: rotate(-45deg);
          }
        }
      }
      .number {
        display: flex;
        justify-content: space-around;
        width: 100%;
        flex-wrap: wrap;
        div {
          width: 33.3%;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 10px;
          .p1 {
            font-size: 14px;
          }
          .p2 {
            font-size: 20px;
            font-weight: bold;
            color: #FFC1AC;
          }
          .p3 {
            font-size: 14px;
            span {
              color: #FFC1AC;
            }
          }
          .p4 {
            color: #999!important;
          }
        }
      }
    }
  }
}
</style>